.header-collapseable-section{                
    transition: max-height 0.4s ease-out;
}

.collpase-button-container{ 
    padding-top: 0;
    margin-top: -10px;
}

.ontology-page-container .header-collapse-btn{  
    background-color: transparent;
    color: black;    
    padding: 0px;    
    margin-bottom: 0;
    border-right: 20px solid transparent;
    border-top: 20px solid white;    
    border-left: 20px solid transparent;
    cursor: pointer;
    text-align: center;    
}

.ontology-page-container .header-collpase-icon{   
    font-size: 22px;
    border: none;
    position: relative;
    top: -20px;
}

.ontology-page-container .ont-info-bar{   
    background-color: white;
    padding: 5px;
    padding-bottom: 1px;    
    margin-bottom: 10px;
    border-radius: 5px;    
    margin-top: -30px;    
}

.ontology-page-container .ont-info-bar-title{   
    color: black;
    font-weight: bold;    
}

.ontology-page-container .ontology-detail-page-container, 
.ontology-page-container .tree-view-container{   
    background-color: white;    
    margin-top: 0px;   
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;    
    margin-left: -15px; 
    margin-right: -15px;   
}

.ontology-detail-table, .ontology-metric-table{  
    border-collapse: collapse;
    width: 92%;
}

.ontology-detail-table  td, th{  
    border: 1px solid #dad2d2;
    text-align: left;
    padding: 8px;
}

.ontology-metric-table  td, th{ 
    border-bottom: 1px solid #DFE4E5;
    text-align: left;
}

.ontology-page-container .copy-link-btn{
    margin-left: 20px;
    font-size: 10px;
}

.ontology-page-container .copy-link-icon-btn{
    margin-left: 10px;
    font-size: 10px;
    background-color: white;
    color: black;
    border: none;
}

.ontology-page-container .download-ontology-btn{ 
    margin-right: 20px;
    background-color: #404040;
    font-size: 14px;
}

.ontology-overview-table-id-column{    
    width: 280px;
}

#annotation-heading{
    border-top: solid 6px #DFE4E5 ;
}

.nav-tabs{ 
    margin-left: -15px;
}

.nav-tabs-node{
    margin-left: 0%;
}

.ontology-page-container .ontology-detail-navbar{
    background-color: #ECF0F0;
    border-radius: 25px;
    margin-bottom: 20px;
}

.ontology-detail-nav-item > .nav-link{ 
    color: #0E6668;
}

.issue-report-btn{
    margin-bottom: 10px;
}

.show-header-arrow-down{
    margin-top: -45px !important;
}
#collectionSuggestionModalDialog{
    max-width: 50% !important;
    margin: auto !important;
}
