body .git-issue-card{
    background-color: white;    
    padding: 10px;
    height: auto;
    text-align: justify;
    border: 1px solid #7AABAC;    
}

body .git-issue-title{
    color: black;
    font-weight: bold;    
}

body .git-issue-tag-link{
    color: black;    
}

body .git-issue-tag-link:hover{
    color: black;
    cursor: pointer;
    text-decoration: none;
}

body .git-issue-label-tag{
    display: inline;
    border-radius: 15px;
    padding: 5px;
    font-size: 12px;
    margin-left: 10px;
}

body .close-mark-btn{
    background-color: white;
}

body .close-term-request-modal-btn{
    background-color: red;
    float: left;
}

body .required_input::after{
    content: "*";
    color: red;    
}

body .rdw-editor-wrapper{
    border: 1px solid rgb(218, 216, 216);
    border-radius: 10px !important;
}

body .rdw-editor-main{
    height: 400px;
    padding: 10px ;
}



