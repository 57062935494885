.tree-view-container .tree-page-left-part{
   padding-bottom: 500px;
   background-color: white;
   width: 100%;
   float: left;
   position: relative; 
   overflow-y: scroll;
   height: 1000px;
   overflow-x: hidden;   
}

.tree-view-container .node-table-container{
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 30px;    
    float: left;
    background-color: white;
    height: 1000px;
    overflow-y: auto;
    width: 100%;
}

.tree-container, .individual-list-container{
    margin-top: 80px;       
}

.tree-action-button-area{
    position: sticky;
    margin-top: -110px;    
    top: 0;        
    float: right;
    z-index: 1000;
    background: white;    
}

.tree-action-end-line{
    width: 90%;    
}

.tree{
    /* 
        The tree wrapper
    */

    margin-top: -40px;
    z-index: 999;
    overflow-x: hidden; 
}

.page-resize-vertical-line{
    cursor: e-resize;
    width: 10px;        
    border-left: 1px solid black;   
}

.resizable-container{
    display: flex;
}

.tree-view-container .reset-tree-btn{
    margin-bottom: 10px;
}

.loading-sign-div{
    margin-left: 100px;
    margin-top: 100px;    
}

.p-icon-style{
    background-color: blue;
    color: white;
    border-radius: 50%;
    text-align: center;
    width: 14px;
    height: 14px;
    font-size: 10px;
    margin-right: 5px;
    display: inline-block;
}

.tree-view-container .node-metadata-label{
    font-weight: bold;
    font-size: 13.5px;
    text-align: left;
    word-wrap: break-word;
    padding-bottom: 10px;
    padding-top: 5px;
}

.tree-view-container .node-metadata-value{
    font-size: 15px;
    padding-bottom: 10px;
    padding-top: 5px;
    overflow: hidden;    
}

.node-detail-table-row{
    border-bottom: 1px solid black;
}


.tree-view-container .tree-node-li {
    padding-left: 2em;
    margin-bottom: 5px;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    cursor: pointer;    
    border-left: 1px dotted;
    border-left-color: gray;
    font-size: 13px;        
}

.tree-node-li::before{
    content: "...";        
}

.leaf-node::before{
    content: "........";
}

.li-label-text{
    display: inline-block;    
}

.tree-text-container{
    display: inline-block;    
    vertical-align: top;
    margin-bottom: 5px;     
    width: 80%;
    position: absolute;
    padding-left: 5px;
}

.tree-text-container:hover{
    background-color: rgb(218, 218, 218);
}

.clicked{
    background-color: rgb(199, 199, 199);
    color: black;
    font-weight: bold;
}

.closed > i{ 
    color: gray;
    margin-right: 8px;
}

.opened > i{   
    color: gray;
    margin-right: 8px;
}

.leaf-node > i{  
    color: gray;
}

.tree-view-container .tree-action-btn{ 
    font-size: 10px;
    margin-bottom: 5px;
    width: 100px;    
}

.node-tag{
    float: left;
    margin-bottom: 10px;
    margin-top: 2px;
}

.synonyms-tag{
    float: left;
    margin-bottom: 10px;
    margin-top: 2px;
}

.tree-view-container .list-node-li{
    padding-left: 2em;
    margin-bottom: 5px;    
    margin-left: 0;
    padding-left: 0;
    cursor: pointer;        
    font-size: 13px;    
}

