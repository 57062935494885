body{
    color: #42484e !important;
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 16px !important;
    overflow-x: hidden !important;
 }

 .application-content{
    padding-top: 0 !important;    
 }

.ahome{  
    font-weight: bold !important;
 }


.term-button{    
    background-color: #873593 !important;
    color: white !important;
    margin-left: 15px !important;
    padding: 3px !important;
    font-size: 12px !important;
    margin-top: -5px !important;
    border-color:  #873593 !important;
 }

.term-button:hover{
    text-decoration: none !important;
    background-color: rgba(149, 86, 158, 0.7) !important;
    color: white !important;
}

.ontology-button{ 
    background-color: #00617C !important; 
    color: white !important;    
    padding: 3px !important;
    font-size: 12px !important;    
    border-color: #00617C !important;
    margin-right: 5px !important;    
    margin-right: 10px !important;        
    margin-top: 2px !important;
}

.ontology-button:hover{ 
    text-decoration: none !important;
    background-color: rgba(0,97,124,0.7) !important;
    color: white !important;
}

.facet-box{    
    background-color: white;
    padding-right: 10px;
    padding-top: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
    padding-left: 10px;
    border: 1px solid #639EAF !important;
}

.result-card{
    background-color: white !important;
    margin-bottom: 10px;
    padding: 10px;
    height: auto;
    text-align: justify;
    border: 1px solid #639EAF !important;
}

.stats-box-text{    
    text-align: center;
}

.site-footer{   
    background: #00617C !important;    
    border-top: 1px #d1d3d9 solid !important;
    padding-top: 20px !important;
    color: white !important;
}

.site-dropdown-menu{ 
    border: 1px solid #00617C !important;
    padding: 5px;
    margin-left: 10px;
}

.header-wrapper{
    background-position: right center !important;
    background-color: white !important;
    padding: 10px !important;
    margin-bottom: 10px !important;    
    background-image: none !important;
    color: black !important;
 }

.site-header-nav-logo-holder{
    padding-right: 10% !important;
    margin-bottom: 30px !important;
    width: 120% !important;
 }
 
.site-header-top-row{  
    text-align: right !important;
    color: #09617B !important;
    font-weight: bold !important;
    margin-bottom: 20px !important;
 }

.main-title {   
    color: #00617c !important;
    text-decoration: none !important;
    font-size: 18px !important;
}
 
.main-title:hover {   
    color: #005D70 !important;
    text-decoration: none !important;
}
   
.site-logo-image{ 
    height: 100px !important;
    width: 100px !important;    
}

.site-navbar { 
     margin-top: 15px !important;
     background-color: white !important;
     width: 90% !important;
}
   
.navbar-item {   
     margin-left: 10px !important;
     text-transform: uppercase !important;
     outline: none !important;
     color: #00617C !important;
     font-weight: bold !important;
     font-size: 20px !important;
}
 
.navbar-item:hover{
    color: #00617C !important;
    text-decoration: underline !important;
}

.home-page-stats-container{ 
    padding: 5% !important;
    padding-bottom: 8% !important;
}

.stats-box{ 
    color: white;
    background-color: #00617C !important;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}

.autocomplete-item:hover{ 
    vertical-align: top;
    background-color: #f5f5f5 !important;
    width: 100%;
}

.jump-autocomplete-container{
    line-height: initial !important;
    height: 50px;
}

.search-input{
    border: 1px solid #404040 !important;
}

.search-btn{ 
    background-color: #404040 !important;
    color: white;    
}

.also-in-ontologies{    
    display: inline-block !important;
    padding-bottom: 7px !important;
}

.nav-tabs .ontology-detail-nav-item .nav-link.active {
    background-color: #FAFAFA !important;
}

.p-icon-style{     
    background-color: #00617C !important;
    color: white;
    border-radius: 50%;
    text-align: center;
    width: 14px;
    height: 14px;
    font-size: 10px;
    margin-right: 5px;
    display: inline-block;

}

.site-header-search-filters-container{
    margin-left: 1% !important;
    color: #42484e !important;
}

body .login-btn-header,
body .login-btn-header:hover{
    color: #00617C !important;
    text-decoration: none !important;
}

.show-header-arrow-down{
  margin-top: -15px !important;
}

.note-dropdown-menu{
    left: -110px !important;
}

.node-table-container .note-dropdown-menu{
    left: 0px !important;
}

.tooltip-questionmark{        
    color: black !important;    
}

#site-warning-banner{
    margin-bottom: 40px !important;
}